import tractor from '../images/tractor.webp';
import truck from '../images/camion.webp';

import caterpillar from '../images/tractors/cat.jpeg';
import bobcat from '../images/tractors/bobcat.jpg';
import case_tractors from '../images/tractors/case.jpg';
import john_deere from '../images/tractors/john deere.webp';
import kobelco from '../images/tractors/kobelco.webp';
import komatsu from '../images/tractors/komatsu.jpg';
import new_holland from '../images/tractors/new holland.webp';
import hitachi from '../images/tractors/hitachi.jpg';

import ingranaggio from '../images/tractors/ingranaggio.png';

import mercedes from '../images/trucks/mercedes.jpg';
import hyunday from '../images/trucks/hyundai.jpg';
import volvo from '../images/trucks/volvo.webp';
import ford from '../images/trucks/ford.webp';

import ingranaggio_camion from '../images/trucks/ingranaggio.png';

import laser_closed from '../images/laser/laser marker.png';
import laser_open from '../images/laser/laser marker open.jpg';
import laser_scope from '../images/laser/laser of the marker.jpg';
import laser_inside from '../images/laser/inside marker lateral.jpg'

import laser_marker_machine from '../images/laser/laser marker machine.png';

import welder from '../images/laser/laser welder.jpeg';
import welder_lateral from '../images/laser/laser welder lateral.jpeg';
import welder_open from '../images/laser/laser welder open.jpeg';

import laser_welder_machine from '../images/laser/laser welder machine.png';

export const productsHome=[
    {
        description: 'Spare parts for tractors and heavy duty machines',
        mainSubject: 'heavy machinery',
        subjects:[
            {
                subject: 'tractors',
                subtitle: 'Top gears for tractors and heavy machinery',
                sb_image: ingranaggio,
                second_subtitle: 'Wide choice of brands and manufacturers with international deliveries for all products',
                image_alt: tractor,
                brands: [caterpillar, komatsu, case_tractors, john_deere, hitachi, bobcat, new_holland, kobelco],
            },
            
            {
                subject: 'trucks',
                subtitle: 'All the parts you need for commercial vehicles',
                sb_image: ingranaggio_camion,
                second_subtitle: 'From the sample piece to the final product with the in house technical drawing production, for many brands of trucks',
                image_alt: truck,
                brands: [mercedes, volvo, hyunday, ford],
            }
        ]
    },

    {
        description: 'High precision laser machines',
        mainSubject: 'industrial machines',
        subjects:[
            {
                subject: 'laser engraver',
                subtitle: 'Engraving machine for any type of surface',
                sb_image: laser_marker_machine,
                second_subtitle: 'The opening and removable cabin and the integrated wheels make the cabin operable on large objects in any place',
                image_alt: "laser",
                brands: [laser_closed, laser_open, laser_scope, laser_inside],
            },

            {
                subject: 'laser welder',
                subtitle: 'High precision laser welder',
                sb_image: laser_welder_machine,
                second_subtitle: 'Easy to carry easy to use, with the cable up to 5 meters',
                image_alt: "laser",
                brands: [welder, welder_lateral, welder_open],
            },
    ]
    }

]